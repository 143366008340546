import { Dispatch, FC, ReactNode, SetStateAction, useEffect } from "react";
import { ReactPortal } from "../hightOrderComponents/reactPortal";
import ArrowLeft from "../assets/icons/arrowLeft.svg?react";

import "../styles/components/portalToolbar.css";

export const PortalToolbar: FC<{
  children: ReactNode;
  show: boolean;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}> = ({ children, show, open, setOpen }) => {
  useEffect(() => {
    if (!show && open) setOpen(false);
  }, [open, show]);

  if (!show) return null;
  return (
    <ReactPortal wrapperId="portal">
      {show && (
        <div className={`portal-toolbar portal-toolbar-opened__${open}`}>
          <div
            className="portal-toolbar-label"
            onClick={() => setOpen((p) => !p)}
          >
            <ArrowLeft />
          </div>
          <div className="portal-toolbar-content">{children}</div>
        </div>
      )}
    </ReactPortal>
  );
};
